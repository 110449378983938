<template>
  <b-card>
    <!-- filter Section -->
    <b-row>
      <b-button
        v-permission="'viewDeservedFees'"
        class="btn-filter"
        variant="adn"
        @click="() => {
          showFilter = !showFilter;
          ResetSelection();
        }"
      >
        {{ !showFilter ? this.$t('showFilter') : this.$t('hideFilter') }}
        <feather-icon
          icon="FilterIcon"
          class="danger"
        />
      </b-button>
    </b-row>

    <b-row
      v-if="showFilter"
      class="filter-section"
    >
      <!-- transaction Number -->
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="transactionNumber"
          v-silent-search
          :value.sync="filter.transactionNumber"
          label-text="transactionNumber"
          name="transactionNumber"
          @keydown.enter="() => { refreshItems(); }
          "
        />
      </b-col>

      <!-- transaction from Date -->
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="font-small-3"
          for="example-datepicker"
        > {{ $t('fromDate') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.fromDate"
          locale="ar"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="fromDate"
          @input="() => refreshItems()"
        />
      </b-col>

      <!-- transaction to Date -->
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="font-small-3"
          for="example-datepicker"
        > {{ $t('toDate') }}
        </label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.toDate"
          locale="ar"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="toDate"
          @input="() => refreshItems()"
        />
      </b-col>

      <!-- fees status -->
      <b-col
        class="gb-package"
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label> {{ $t("status") }} </label>
        <b-button-group>
          <b-button
            :class="['gb-btn-child', { btnActive: feesStatusName === 'all' }]"
            variant="flat-warning"
            @click="() => {
              feesStatusName = 'all'
              refreshItems()
            }"
          >
            {{ $t("all") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', { btnActive: feesStatusName === 'convertedToSale' }]"
            variant="flat-warning"
            @click="() => {
              feesStatusName = 'convertedToSale'
              refreshItems()
            }"
          >
            {{ $t("convertedToSale") }}
          </b-button>

          <b-button
            :class="['gb-btn-child', { btnActive: feesStatusName === 'newTransaction' }]"
            variant="flat-warning"
            @click="() => {
              feesStatusName = 'newTransaction'
              refreshItems()
            }"
          >
            {{ $t("newTrans") }}
          </b-button>
        </b-button-group>
      </b-col>

      <!-- student Name -->
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="studentName"
          v-silent-search
          :value.sync="filter.studentName"
          label-text="studentName"
          name="studentName"
          @keydown.enter="() => { refreshItems(); }
          "
        />
      </b-col>

      <!-- stage -->
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.stageId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="stage"
          field="select"
          :options="stages"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <!-- row  -->
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.rowId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="row"
          field="select"
          :options="rows"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>

      <!-- classroom -->
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.classroomId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="class"
          field="select"
          :options="classrooms"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => refreshItems()"
          @input="() => refreshItems()"
        />
      </b-col>
    </b-row>

    <!-- buttons operations -->
    <b-row
      align-h="between"
      class="mb-1 mt-1"
    >
      <b-col
        md="12"
        class="list-buttons d-flex align-items-center justify-content-end mb-1 mb-md-0"
      >
        <b-button
          v-permission="'addDeservedFees'"
          variant="primary"
          data-action-type="new"
          @click="
            (v) => {
              this.$router.push({ name: 'deservedFees-new' });
            }
          "
        >
          {{ $t("new") }}
        </b-button>
        <vue-excel-xlsx
          :data="itemsArray"
          :columns="tableColumns"
          :filename="this.$route.name"
          :sheetname="'xlsxSheet'"
          class="btn btn-relief-success ml-1 p-0 ptn-sm"
        >
          <img
            src="@/assets/images/icons/xls.jpg"
            alt=""
            style="height: 39px; width: auto"
          >
        </vue-excel-xlsx>
        <b-button
          variant="relief-danger"
          class="ml-1 p-0 ptn-sm"
          @click="printDocument"
        >
          <img
            src="@/assets/images/icons/pdf.jpg"
            alt=""
            style="height: 39px; width: auto"
          >
        </b-button>
      </b-col>
    </b-row>

    <!-- preview Table -->
    <g-table
      ref="deservedFees-table"
      :items="itemsProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      foot-clone
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
      <template
        v-if="!hideActions"
        #actions="{ item }"
      >
        <div class="text-nowrap">

          <!-- convert to sales invoice button -->
          <b-button
            v-if="!item.isConvertedToSale"
            v-permission="'convertToSalesInvoice'"
            v-b-tooltip.hover.top="$t('convertToSalesInvoice')"
            data-action-type="edit"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="convertToSalesInvoice(item)"
          >
            <feather-icon
              icon="ShoppingBagIcon"
              stroke="sienna"
            />
          </b-button>

          <!-- edit button -->
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editSalesTransactions'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click="editDeservedFees(item)"
          >
            <feather-icon
              icon="EditIcon"
              class="mx-25 clickable"
              stroke="lightslategray"
            />
          </b-button>

          <!-- print button -->
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click="(v) => {
              print(item);
            }
            "
          >
            <feather-icon
              icon="PrinterIcon"
              stroke="olivedrab"
            />
          </b-button>

          <!-- delete button  -->
          <b-button
            v-if="!item.isConvertedToSale"
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deletediscount'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      filter: {
        transactionNumber: null,
        fromDate: null,
        toDate: null,
        studentName: null,
        stageId: null,
        rowId: null,
        classroomId: null,
        isConvertedToSale: null
      },
      items: [],
      totalRows: 0,
      showFilter: false,
      feesStatusName: 'all',
      fiscalYearStart: null,
      fiscalYearEnd: null,
      encryptedId: '',
      itemsArray: [],
      hideActions: false,
      url: '',
    };
  },

  computed: {
    tableColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('code'),
          sortable: true
        },
        {
          key: 'transactionDate',
          label: this.$t('transactionDate'),
          sortable: true
        },
        {
          key: 'studentNameAr',
          label: this.$t('studentName'),
          sortable: true
        },
        {
          key: 'total',
          label: this.$t('total'),
          sortable: true,
          type: 'number',
          footer: () => this.fraction(this.totalVal),
        },
        {
          key: 'discountValue',
          label: this.$t('discount'),
          sortable: true,
          type: 'number',
          footer: () => this.fraction(this.totalDiscount),
        },
        {
          key: 'net',
          label: this.$t('net'),
          sortable: true,
          type: 'number',
          footer: () => this.fraction(this.totalNet),
        },
        {
          key: 'status',
          field: 'status',
          label: this.$t('status'),
          sortable: false,
          formatter: (key, value, item) => {
            if (item.isConvertedToSale) {
              return `
                <span
                  class="badge bg-success bg-darken-2 p-50 main-space"
                >
                  ${ this.$t('convertedToSaleInvoice') }
                </span>`;

            // otherwise
            }else {
              if (item.priceTrafficedValue > 0) {
                return `
                  <span
                    class="badge bg-yahoo bg-darken-2 p-50 main-space"
                  >
                    ${this.$t('someInvoicesCreated')}
                  </span>`;
              }else {
                return `
                  <span
                    class="badge bg-danger bg-darken-2 p-50 main-space"
                  >
                    ${this.$t('newTrans')}
                  </span>`;
              }
            }
          }
        },
        { key: 'actions' },
      ];
    },
  },

  watch: {
    'filter.fromDate'(newVal) {
      if (newVal > this.filter.toDate) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    },
    'filter.ToDate'(newVal) {
      if (this.filter.fromDate > newVal) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    }
  },

  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },

  mounted() {
    this.filter.fromDate = this.getDate(this.fiscalYearStart)
    this.filter.toDate = this.getDate(this.fiscalYearEnd)
    const prodction = document.location.host.indexOf('eduprosys.net');
    if (prodction !== -1) {
      this.url = "https://eduproreporting.azurewebsites.net/"
    } else {
      this.url= "http://108.181.191.44:3031/"
    }
    this.loadData();
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    loadData() {
      this.get({ url: 'Stages' }).then((data) => {
        this.stages = data;
      });
      this.get({ url: 'Rows' }).then((data) => {
        this.rows = data;
      });
      this.get({ url: 'Classrooms' }).then((data) => {
        this.classrooms = data;
      });
    },
    printDocument() {
      this.hideActions = true;
      setTimeout(() => {
        this.pdfExport(this.$route.name);
        this.hideActions = false;
      }, 500);
    },
    pdfExport(name) {
      html2canvas(document.getElementById("mainTable")).then(function (canvas) {
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${name}.pdf`);
      });
    },
    setFilertForTransaction() {
      switch (this.feesStatusName) {
        case 'convertedToSale':
          this.filter.isConvertedToSale = true;
          break;

        case 'newTransaction':
          this.filter.isConvertedToSale = false;
          break;

        default:
          this.filter.isConvertedToSale = null;
          break;
      }
    },
    itemsProvider(ctx, callback) {
      const
        {
          currentPage, perPage,
          sortBy, sortDesc
        } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      var params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      this.setFilertForTransaction();
      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;

      this.get({ url: `DeservedFees${params}` })
        .then(({ pageData , totalRecords }) => {
          this.isTableBusy = false;
          this.totalVal = 0;
          this.totalDiscount = 0;
          this.totalNet = 0;
          this.totalRows = totalRecords;
          pageData.forEach((item) => {
            this.totalVal += item.total;
            this.totalDiscount += item.discountValue;
            this.totalNet += item.net;
            item.invoiceDate = this.getDate(item.invoiceDate);
            item.transactionDate = this.getDate(item.transactionDate);
          });
          callback(pageData);

          // then set items for excel and pdf
          this.itemsArray = pageData;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
    refreshItems() {
      this.$refs['deservedFees-table'].refreshTable();
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'DeservedFees', id: item.id }).then(() => {
            this.doneAlert({
              text: this.$t('deletedSuccessfully')

            });
            this.refreshItems();
          });
        }
      );
    },
    editDeservedFees(item) {
      this.$router.push({
        name: 'deservedFees-edit',
        params: { id: item.id, isConvertToSale: false},
      });
    },
    print(item) {
      const printedItem = {
        id: item.id
      }
      this.printReport(this.isRight ? 'DeservedFee-ar' : 'DeservedFee-en', printedItem);
    },
    convertToSalesInvoice(item){
      this.$router.push({
        name: 'deservedFees-edit',
        params: { id: item.id, isConvertToSale: true},
      });
    },
    ResetSelection() {
      if (!this.showFilter) {
        this.filter.transactionNumber = null;
        this.filter.fromDate = this.fiscalYearStart;
        this.filter.toDate = this.fiscalYearEnd;
        this.filter.studentName = null;
        this.filter.stageId = null;
        this.filter.rowId = null;
        this.filter.classroomId = null;
        this.filter.isConvertedToSale = null;
        this.feesStatusName = 'all';
        this.refreshItems();
      }
    },
  },

};
</script>

<style>
.filter-section {
  background-color: #f9f9f9;
  margin: 15px 0;
  padding: 25px 0 5px;
  border: 2px solid #ad8884;
}

.gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: -3px;
}

.gb-package label {
  font-size: 14px;
  margin-bottom: 10px;
}

b-button-group {
  border-radius: 6px;
}

.gb-btn-child {
  border: 1px solid gainsboro;
}

.btnActive {
  background-color: rgba(255, 174, 92, 0.2)
}

.btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
